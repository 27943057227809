import { render, staticRenderFns } from "./ModalModificated.vue?vue&type=template&id=1f7c2266&scoped=true&"
import script from "./ModalModificated.vue?vue&type=script&lang=js&"
export * from "./ModalModificated.vue?vue&type=script&lang=js&"
import style0 from "./ModalModificated.vue?vue&type=style&index=0&id=1f7c2266&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f7c2266",
  null
  
)

export default component.exports